import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    width: '100%'
  },
});

const getDateAndTime = (dateAndTime) => {
  var dateObj = new Date(dateAndTime);
  var stringToReturn = ''
  stringToReturn += dateObj.getDate() + "/"
  stringToReturn += (dateObj.getMonth() + 1) + "/"
  stringToReturn += dateObj.getFullYear() + " "
  var hour = dateObj.getHours()
  if (String(hour).length < 2) {
    hour = "0" + hour
  }
  var min = dateObj.getMinutes()
  if (String(min).length < 2) {
    min = "0" + min
  }
  stringToReturn += hour + ":"
  stringToReturn += min
  return stringToReturn
}


export default function SimpleTable(props) {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Request Name</TableCell>
            <TableCell align="left">Product</TableCell>
            <TableCell align="left">Status</TableCell>
            <TableCell align="left">Address</TableCell>
            <TableCell align="left">Date</TableCell>
            <TableCell align="left">Total Cost</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.rows.map(row => (
            <TableRow key={row.name}>
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="left">{row.product}</TableCell>
              <TableCell align="left">{row.status}</TableCell>
              <TableCell align="left">{row.location}</TableCell>
              <TableCell align="left">{getDateAndTime(row.date)}</TableCell>
              <TableCell align="left">{row.totalCost}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
