import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import PaymentsOption from './PaymentsOption';
import Paper from '@material-ui/core/Paper';
import axios from 'axios';
import Cookies from 'universal-cookie';
import uuid from 'react-native-uuid';
import CircularProgress from '@material-ui/core/CircularProgress';
import DescriptionDialog from '../Dialog/DescriptionDialog.js'
import {
  BrowserRouter as Router,
  Route,
  Link
} from 'react-router-dom';

import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import RequestsTable from './requestsTable';
import CreateDocumentDialog from './CreateDocumentDialog';
import PaymentsDetailsDialog from './PaymentsDetailsDialog';
import CancelParentOrderDialog from './CancelParentOrderDialog';
import RequestsOrPackage from './RequestsOrPackage';
import PaymentOptions from './PaymentOptions';

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  grow: {
    flexGrow: 1,
    fontSize: '20px',
    textAlign: 'left'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 2,
  },
});


class Login extends React.Component {

  state = {
    titles: ['Enter your phone number', 'Enter verification code', 'Are you a Photograher or a Client?','One last step and you are inside','One last step and you are inside'],
    openAlert: false,
    isLoading: false,
    paymentIsReady: false,
    order: null,
    account: null,
    product: null,
    requests: null,
    phoneNumber: '',
    alertText: '',
    vatType: 0,
    totalSum: 0,
    paymentOption: 1,
    payfor: 1,
    regNumber: '',
    city: '',
    street: '',

    paymentURL: null,

    ordersToCharge: [],
    companyNameForInvoice: '',
    account: null,
    contact: null,
    paymentIsReady: false,
    phoneNumber: '',
    email: '',
    openPackages: [],

    createDocumentDialogStatus: false,
    PaymentsDetailsDialog: false,
    // createDocumentDialogContent: (<iframe style={{frameBorder: 0, borderRadius: 0, border: 0}} srcdoc="<html><body>Hello, <b>world</b>.</body></html>"></iframe>)
    createDocumentDialogContent: (<div><center><CircularProgress style={{marginTop: 80}} /></center></div>),

    cancelParentOrderDialogStatus: false,
    cancelParentOrderDialogContent: (<div><center><CircularProgress style={{marginTop: 80}} /></center></div>),

    date: new Date(),
    date_payment: '',
    date_payment_display: new Date(),
    // Cheque
    bank: '',
    bankBranch: '',
    bankAccount: '',
    chequeNumber: '',
    // Bank Transfter
    bank: '',
    bankBranch: '',
    accountNumber: '',

    paymentDocType: '',

    typeOfPayment: '',

    cancelSingleOrder: false,

    payments: [],

    allowToSelect: 'orderparent',
    
    parentOrderSelected: false,

    allowInvoice: true,
    selectedPackageToPayFor: null

  };

  getTotalSum = () => {

    var totalSum = 0
    for (var i = 0; i < this.state.payments.length; i++) {
      totalSum += parseFloat(this.state.payments[i].sum)
    }
    return totalSum
  }

  handleChangeText = name => event => {
    this.setState({ [name]: String(event.target.value) });
  };

  addNewLineOfPayment = (data) => {
    var payments = this.state.payments
    payments.push(data)
    this.setState({payments: payments})
  }

  onChangeDate = (date:Date) => {
    console.log('Date: ', date)
    this.setState({date: date})
  }

  handleChangeSelect = name => event => {
    console.log(name);
    console.log(event.target.value);
    this.setState({ [name]: event.target.value });
  };

  getParameterByName = (name, url) => {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  deleteLineOfPayment = (i) => {
    var array = this.state.payments
    array.splice(i, 1)
    this.setState({payments: array})
  }

  createDocumentWithoutPayment = (type) => {
    this.setState({
      createDocumentDialogContent: (<div style={{width: '100%'}}>
          <center>
            <Button onClick={() => this.createDocumentBeforePayment(type)} elevation={0} style={{marginTop: 70, textTransform: 'none', height: 40, width: 160, borderRadius: 20, textColor: 'white', color: 'white', background: "linear-gradient(to right , #544cf8, #814bfa)"}}>
              <b>Create {type} doc</b>
            </Button>
          </center>
        </div>)
    }, ()=> {
      this.setState({createDocumentDialogStatus: true})
    })
  }

  cancelParentOrderDialog = (id) => {
    this.setState({
      cancelParentOrderDialogContent: (<div style={{width: '100%'}}>
          <center>
            <Button onClick={() => this.cancelParentOrder(id)} elevation={0} style={{marginTop: 70, textTransform: 'none', height: 40, width: 160, borderRadius: 20, textColor: 'white', color: 'white', background: "linear-gradient(to right , red, red)"}}>
              <b>Cancel Parent Order</b>
            </Button>
          </center>
        </div>)
    }, ()=> {
      this.setState({cancelParentOrderDialogStatus: true})
    })
  }

  cancelParentOrder = (id) => {
    var type = "Credit invoice"
    console.log("cancel ", id);
    this.setState({cancelParentOrderDialogContent: (<div><center><CircularProgress color={'red'} style={{marginTop: 80, marginLeft: 0}} /></center></div>)}, ()=> {
    })

    var count = 0


      var regNumber = ""
      var street = ""
      var country = ""
      var city = ""
      var email = ""
      var name = ""

      if (this.state.account.Company_Registration_Number__c == null) {
        regNumber = ''
      } else {
        regNumber = this.state.account.Company_Registration_Number__c
      }

      if (this.state.account.BillingAddress != null) {
        if (this.state.account.BillingAddress.street == null) {
          street = ''
        } else {
          street = this.state.account.BillingAddress.street
        }

        if (this.state.account.BillingAddress.countryCode == null) {
          country = ''
        } else {
          country = this.state.account.BillingAddress.countryCode
        }

        if (this.state.account.BillingAddress.city == null) {
          city = ""
        } else {
          city = this.state.account.BillingAddress.city
        }
      } else {
        alert('No at least city for billing address')
      }
      if (this.state.account.PersonEmail != null) {
        email = this.state.account.PersonEmail
      } else {
        if (this.state.account.Company_Email_for_Invoices__c == null) {
          alert('No email exists')
        } else {
          email = this.state.account.Company_Email_for_Invoices__c
        }
      }

      if (this.state.account.Name != null) {
        name = this.state.account.Name
      }

      if (this.state.account.Company_Name_for_Invoices__c != null) {
        name = this.state.account.Company_Name_for_Invoices__c
      }

      var requestsAsString = ''
      var ordersToCharge = this.state.ordersToCharge
      for (var i = 0; i < ordersToCharge.length; i++) {
        if (ordersToCharge[i].include) {
          for (var j = 0; j < ordersToCharge[i].requests.length; j++) {
            if (requestsAsString === '') {
              requestsAsString += ordersToCharge[i].requests[j].Id
            } else {
              requestsAsString += '-' + ordersToCharge[i].requests[j].Id
            }
          }
        }
      }

      console.log(requestsAsString);

      if (this.state.account.Id  == null) {
        alert("Account ID is missing")
      } else if (this.state.account.PersonMobilePhone == null && this.state.account.Phone == null) {
        alert("Person mobile phone is missing")
      } else if (this.state.account.BillingAddress == null) {
        alert("City under billing address is missing")
      } else if (this.state.account.PersonEmail == null && this.state.account.Company_Email_for_Invoices__c == null) {
        alert('No email exists')
      } else if (this.state.account.Name == null && this.state.account.Company_Name_for_Invoices__c == null) {
        alert('No name or name for invoice exists')
      } else if (this.state.account.CurrencyIsoCode == null) {
        alert("Currency is missing")
      } else {
          var data = {
            parentOrderId: id,
            requestIds: requestsAsString,
            accountId: this.state.account.Id,
            contactId: this.state.contact.Id,
            name: name,
            mobile: this.state.phoneNumber,
            companyNumber: regNumber,
            email: 'avichay.official@gmail.com',
            address: street + " " + city + " " + country,
            docType: type,
            price: (this.getTotalAmountToCharge() * 1.17).toFixed(2),
            currencyCode: this.state.account.CurrencyIsoCode,
            countryCode: country,
            vatType: this.state.vatType
          }
          if (type == 'receipt' || type == 'invoiceReceipt') {
            data['payments'] = this.state.payments
          }
          console.log('doc data:')
          console.log(data)
          var serviceLink = ''
          if (this.state.cancelSingleOrder) {
            data['orderId'] = id
            serviceLink = 'https://wesnapp.co.il/api/payment/cancel-order-invoice'
          } else {
            serviceLink = 'https://wesnapp.co.il/api/payment/cancel-parent-order'
          }
          axios.post(serviceLink, data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
            console.log(response.data);
            this.setState({cancelParentOrderDialogContent: (<iframe style={{frameBorder: 0, borderRadius: 0, border: 0}} srcdoc={response.data}></iframe>)})
          })
          .catch( (error) => {
            console.log(error);
          });
      }

    // axios.post('https://wesnapp.co.il/api/payment/cancel-parent-order', data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
    //   console.log(response.data);
    //   this.setState({cancelParentOrderDialogContent: (<iframe style={{frameBorder: 0, borderRadius: 0, border: 0}} srcdoc={response.data}></iframe>)})
    // })
    // .catch( (error) => {
    //   console.log(error);
    // });

  }

  createDocumentBeforePayment = (type) => {

    if (type == 'payment') {
      if (this.state.typeOfPayment == 'invoiceReceipt') {
        type = 'invoiceReceipt'
      } else {
        type = 'receipt'
      }
    }

    this.setState({PaymentsDetailsDialog: false, createDocumentDialogContent: (<div><center><CircularProgress style={{marginTop: 80, marginLeft: 0}} /></center></div>)}, ()=> {
      this.setState({createDocumentDialogStatus: true})
    })

    var count = 0

    if (this.getTotalAmountToCharge() > 0) {

      var regNumber = ""
      var street = ""
      var country = ""
      var city = ""
      var email = ""
      var name = ""

      if (this.state.account.Company_Registration_Number__c == null) {
        regNumber = ''
      } else {
        regNumber = this.state.account.Company_Registration_Number__c
      }

      if (this.state.account.BillingAddress != null) {
        if (this.state.account.BillingAddress.street == null) {
          street = ''
        } else {
          street = this.state.account.BillingAddress.street
        }

        if (this.state.account.BillingAddress.countryCode == null) {
          country = ''
        } else {
          country = this.state.account.BillingAddress.countryCode
        }

        if (this.state.account.BillingAddress.city == null) {
          city = ""
        } else {
          city = this.state.account.BillingAddress.city
        }
      } else {
        alert('No at least city for billing address')
      }
      if (this.state.account.PersonEmail != null) {
        email = this.state.account.PersonEmail
      } else {
        if (this.state.account.Company_Email_for_Invoices__c == null) {
          alert('No email exists')
        } else {
          email = this.state.account.Company_Email_for_Invoices__c
        }
      }

      if (this.state.account.Name != null) {
        name = this.state.account.Name
      }

      if (this.state.account.Company_Name_for_Invoices__c != null) {
        name = this.state.account.Company_Name_for_Invoices__c
      }

      var requestsAsString = ''
      var ordersToCharge = this.state.ordersToCharge
      for (var i = 0; i < ordersToCharge.length; i++) {
        if (ordersToCharge[i].include) {
          if (ordersToCharge[i].type === 'order') {
            for (var j = 0; j < ordersToCharge[i].requests.length; j++) {
              if (requestsAsString === '') {
                requestsAsString += ordersToCharge[i].requests[j].Id
              } else {
                requestsAsString += '-' + ordersToCharge[i].requests[j].Id
              }
            }
          } else {
            for (var z = 0; z < ordersToCharge[i].orders.length; z++) {
              const childsOrders = ordersToCharge[i].orders[z]
              for (var t = 0; t < childsOrders.requests.length; t++) {
                if (requestsAsString === '') {
                  requestsAsString += childsOrders.requests[t].Id
                } else {
                  requestsAsString += '-' + childsOrders.requests[t].Id
                }
              }
            }
          }
        }
      }

      console.log(requestsAsString);

      if (this.state.account.Id  == null) {
        alert("Account ID is missing")
      } else if (this.state.account.PersonMobilePhone == null && this.state.account.Phone == null) {
        alert("Person mobile phone is missing")
      } else if (this.state.account.BillingAddress == null) {
        alert("City under billing address is missing")
      } else if (this.state.account.PersonEmail == null && this.state.account.Company_Email_for_Invoices__c == null) {
        alert('No email exists')
      } else if (this.state.account.Name == null && this.state.account.Company_Name_for_Invoices__c == null) {
        alert('No name or name for invoice exists')
      } else if (this.state.account.CurrencyIsoCode == null) {
        alert("Currency is missing")
      } else {
          var data = {
            requestIds: requestsAsString,
            accountId: this.state.account.Id,
            contactId: this.state.contact.Id,
            name: name,
            mobile: this.state.phoneNumber,
            companyNumber: regNumber,
            email: 'avichay.official@gmail.com',
            address: street + " " + city + " " + country,
            docType: type,
            price: (this.getTotalAmountToCharge() * 1.17).toFixed(2),
            currencyCode: this.state.account.CurrencyIsoCode,
            countryCode: country,
            vatType: this.state.vatType
          }
          if (type === 'receipt' || type === 'invoiceReceipt') {
            data['payments'] = this.state.payments
          }
          console.log('doc data:')
          console.log(data)
          axios.post('https://wesnapp.co.il/api/payment/create-document', data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
            // axios.post('http://localhost:8080/api/payment/create-document', data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
            console.log(response.data);
            this.setState({createDocumentDialogContent: (<iframe style={{frameBorder: 0, borderRadius: 0, border: 0}} srcdoc={response.data}></iframe>)})
          })
          .catch( (error) => {
            console.log(error);
          });
      }
    } else {
        alert('Not enougth money')
    }
  }

  componentDidMount() {
    var wsOrder = this.getParameterByName('id');
    if (wsOrder !== undefined && wsOrder !== '' && wsOrder !== null) {
      this.setState({isLoading: true}, () => {
        this.getOrderDetails(wsOrder)
      })
    } else {
      alert("No Order ID")
    }
  }

  getPaymentLink = async (paymentData) => {

    let paymentUrlRessponse = await axios.post('https://wesnapp.co.il/api/payment/get-payment-url', paymentData, {headers: {'Content-Type': 'application/json'}})
    // let paymentUrlRessponse = await axios.post('http://localhost:8080/api/payment/get-payment-url', paymentData, {headers: {'Content-Type': 'application/json'}})
    this.setState({paymentURL: paymentUrlRessponse.data})

  }

  generateBillingForm = () => {

    var count = 0

    if (this.getTotalAmountToCharge() > 0) {

      var regNumber = ""
      var street = ""
      var country = ""
      var city = ""
      var email = ""
      var name = ""

      if (this.state.account.Company_Registration_Number__c == null) {
        regNumber = ''
      } else {
        regNumber = this.state.account.Company_Registration_Number__c
      }

      if (this.state.account.BillingAddress != null) {
        if (this.state.account.BillingAddress.street == null) {
          street = ''
        } else {
          street = this.state.account.BillingAddress.street
        }

        if (this.state.account.BillingAddress.countryCode == null) {
          country = ''
        } else {
          country = this.state.account.BillingAddress.countryCode
        }

        if (this.state.account.BillingAddress.city == null) {
          city = ""
        } else {
          city = this.state.account.BillingAddress.city
        }
      } else {
        alert('No at least city for billing address')
      }
      if (this.state.account.PersonEmail != null) {
        email = this.state.account.PersonEmail
      } else {
        if (this.state.account.Company_Email_for_Invoices__c == null) {
          alert('No email exists')
        } else {
          email = this.state.account.Company_Email_for_Invoices__c
        }
      }

      if (this.state.account.Name != null) {
        name = this.state.account.Name
      }

      if (this.state.account.Company_Name_for_Invoices__c != null) {
        name = this.state.account.Company_Name_for_Invoices__c
      }

      var requestsAsString = ''
      var requestsArray = []
      var ordersToCharge = this.state.ordersToCharge
      for (var i = 0; i < ordersToCharge.length; i++) {
        if (ordersToCharge[i].include) {
          if (ordersToCharge[i].type === 'order') {
            for (var j = 0; j < ordersToCharge[i].requests.length; j++) {
              if (requestsAsString === '') {
                requestsAsString += ordersToCharge[i].requests[j].Id
                requestsArray.push(ordersToCharge[i].requests[j].Id)
              } else {
                requestsAsString += '-' + ordersToCharge[i].requests[j].Id
                requestsArray.push(ordersToCharge[i].requests[j].Id)
              }
            }
          } else {
            for (var z = 0; z < ordersToCharge[i].orders.length; z++) {
              const childsOrders = ordersToCharge[i].orders[z]
              for (var t = 0; t < childsOrders.requests.length; t++) {
                if (requestsAsString === '') {
                  requestsAsString += childsOrders.requests[t].Id
                  requestsArray.push(childsOrders.requests[t].Id)
                } else {
                  requestsAsString += '-' + childsOrders.requests[t].Id
                  requestsArray.push(childsOrders.requests[t].Id)
                }
              }
            }
          }
        }
      }

      console.log(requestsAsString);

      if (this.state.account.Id  == null) {
        alert("Account ID is missing")
      } else if (this.state.account.PersonMobilePhone == null && this.state.account.Phone == null) {
        alert("Person mobile phone is missing")
      } else if (this.state.account.BillingAddress == null) {
        alert("City under billing address is missing")
      } else if (this.state.account.PersonEmail == null && this.state.account.Company_Email_for_Invoices__c == null) {
        alert('No email exists')
      } else if (this.state.account.Name == null && this.state.account.Company_Name_for_Invoices__c == null) {
        alert('No name or name for invoice exists')
      } else if (this.state.account.CurrencyIsoCode == null) {
        alert("Currency is missing")
      } else {

          if (this.state.payfor === 1) {
            // create payment try in the db and get the ID
            let paymentData = {
              // Account 
              accountId: this.state.account.Id,
              contactId: this.state.contact.Id,
              // Amount
              totalAmountToPay: parseFloat(this.getTotalAmountToCharge() * 1.17).toFixed(2),
              // Coupon
              couponCode: null,
              couponType: '',
              couponAmount: '',
              // Payment For
              paymentFor: 4,
              requests: requestsArray,
              // paymentType
              paymentType: "1"
            }      
            console.log('paymentData', paymentData)
            this.getPaymentLink(paymentData) 
        } else {

          let packagePublicName = ''
          for (let index = 0; index < this.state.openPackages.length; index++) {
            const openPackage = this.state.openPackages[index];
            if (this.state.selectedPackageToPayFor === openPackage.Id) {
              packagePublicName = openPackage.PackageName
            }
          }

          let paymentData = {
            // Account 
            accountId: this.state.account.Id,
            contactId: this.state.contact.Id,
            // Amount
            totalAmountToPay: parseFloat(this.getTotalAmountToCharge() * 1.17).toFixed(2),
            // Coupon
            couponCode: null,
            couponType: '',
            couponAmount: '',
            // Payment For
            paymentFor: 7,
            packagePublicName: packagePublicName,
            orderId: this.state.selectedPackageToPayFor,
            // paymentType
            paymentType: "1"
          }      
          console.log('paymentData', paymentData)
          this.getPaymentLink(paymentData) 
        }

      }
    } else {
      this.setState({paymentURL: null})
    }
  }

  generateBillingFormWithPayments = () => {
    if (this.getTotalAmountToCharge() > 1000) {

      var regNumber = ""
      var street = ""
      var country = ""
      var city = ""
      var email = ""
      var name = ""

      if (this.state.account.Company_Registration_Number__c == null) {
        regNumber = ''
      } else {
        regNumber = this.state.account.Company_Registration_Number__c
      }

      if (this.state.account.BillingAddress != null) {
        if (this.state.account.BillingAddress.street == null) {
          street = ''
        } else {
          street = this.state.account.BillingAddress.street
        }

        if (this.state.account.BillingAddress.countryCode == null) {
          country = ''
        } else {
          country = this.state.account.BillingAddress.countryCode
        }

        if (this.state.account.BillingAddress.city == null) {
          city = ""
        } else {
          city = this.state.account.BillingAddress.city
        }
      } else {
        alert('No at least city for billing address')
      }
      if (this.state.account.PersonEmail != null) {
        email = this.state.account.PersonEmail
      } else {
        if (this.state.account.Company_Email_for_Invoices__c == null) {
          alert('No email exists')
        } else {
          email = this.state.account.Company_Email_for_Invoices__c
        }
      }

      if (this.state.account.Name != null) {
        name = this.state.account.Name
      }

      if (this.state.account.Company_Name_for_Invoices__c != null) {
        name = this.state.account.Company_Name_for_Invoices__c
      }

      var requestsAsString = ''
      var requestsArray = []
      var ordersToCharge = this.state.ordersToCharge
      for (var i = 0; i < ordersToCharge.length; i++) {
        if (ordersToCharge[i].include) {
          if (ordersToCharge[i].type === 'order') {
            for (var j = 0; j < ordersToCharge[i].requests.length; j++) {
              if (requestsAsString === '') {
                requestsAsString += ordersToCharge[i].requests[j].Id
                requestsArray.push(ordersToCharge[i].requests[j].Id)
              } else {
                requestsAsString += '-' + ordersToCharge[i].requests[j].Id
                requestsArray.push(ordersToCharge[i].requests[j].Id)
              }
            }
          } else {
            for (var z = 0; z < ordersToCharge[i].orders.length; z++) {
              const childsOrders = ordersToCharge[i].orders[z]
              for (var t = 0; t < childsOrders.requests.length; t++) {
                if (requestsAsString === '') {
                  requestsAsString += childsOrders.requests[t].Id
                  requestsArray.push(childsOrders.requests[t].Id)
                } else {
                  requestsAsString += '-' + childsOrders.requests[t].Id
                  requestsArray.push(childsOrders.requests[t].Id)
                }
              }
            }
          }
        }
      }

      console.log(requestsAsString);

      if (this.state.account.Id  == null) {
        alert("Account ID is missing")
      } else if (this.state.account.PersonMobilePhone == null && this.state.account.Phone == null) {
        alert("Person mobile phone is missing")
      } else if (this.state.account.BillingAddress == null) {
        alert("City under billing address is missing")
      } else if (this.state.account.PersonEmail == null && this.state.account.Company_Email_for_Invoices__c == null) {
        alert('No email exists')
      } else if (this.state.account.Name == null && this.state.account.Company_Name_for_Invoices__c == null) {
        alert('No name or name for invoice exists')
      } else if (this.state.account.CurrencyIsoCode == null) {
        alert("Currency is missing")
      } else {

        var maxpay = "2"
        var sum = parseFloat(this.getTotalAmountToCharge() * 1.17).toFixed(2)
        if (sum >= 1000 && sum < 2000) {
          maxpay = "2"
        } else if (sum >= 2000 && sum < 3000) {
          maxpay = "3"
        } else if (sum >= 3000 && sum < 4000) {
          maxpay = "4"
        } if (sum >= 5000) {
          maxpay = "5"
        }

        if (this.state.payfor === 1) {
          // create payment try in the db and get the ID
          let paymentData = {
            // Account 
            accountId: this.state.account.Id,
            contactId: this.state.contact.Id,
            // Amount
            totalAmountToPay: parseFloat(this.getTotalAmountToCharge() * 1.17).toFixed(2),
            // Coupon
            couponCode: null,
            couponType: '',
            couponAmount: '',
            // Payment For
            paymentFor: 4,
            requests: requestsArray,
            // paymentType
            paymentType: "8",
            maxPayments: maxpay
          }      
          console.log('paymentData', paymentData)
          this.getPaymentLink(paymentData) 
        } else {

          let packagePublicName = ''
          for (let index = 0; index < this.state.openPackages.length; index++) {
            const openPackage = this.state.openPackages[index];
            if (this.state.selectedPackageToPayFor === openPackage.id) {
              packagePublicName = openPackage.PackageName
            }
          }

          let paymentData = {
            // Account 
            accountId: this.state.account.Id,
            contactId: this.state.contact.Id,
            // Amount
            totalAmountToPay: parseFloat(this.getTotalAmountToCharge() * 1.17).toFixed(2),
            // Coupon
            couponCode: null,
            couponType: '',
            couponAmount: '',
            // Payment For
            paymentFor: 7,
            packagePublicName: packagePublicName,
            orderId: this.state.selectedPackageToPayFor,
            // paymentType
            paymentType: "8",
            maxPayments: maxpay
          }      
          console.log('paymentData', paymentData)
          this.getPaymentLink(paymentData) 
        }
      }
    } else {
      this.setState({paymentURL: null})
    }
  }

  createData = (name, product, status, location, date, totalCost) => {
    return { name, product, status, location, date, totalCost };
  }

  getOrderDetails = async (id) => {
    let packagesRes = await axios.post('https://wesnapp.co.il/api/order/get-unpaid-packages-orders', {id: id}, {headers: {'Content-Type': 'application/json'}})
    let unpaidPackages = []
    if (packagesRes.data.length > 0) {
      for (let index = 0; index < packagesRes.data.length; index++) {
        const unpaidPackage = packagesRes.data[index];
        if (unpaidPackage.WS_Product__c) {
          let productResponse = await axios.post('https://wesnapp.co.il/api/product/get-one', {id: unpaidPackage.WS_Product__c}, {headers: {'Content-Type': 'application/json'}})
          if (productResponse.data.length > 0) {
            console.log('productResponse', productResponse)
            unpaidPackage.PackageTotalCost = productResponse.data[0].Total_Cost__c
            unpaidPackage.PackageName = productResponse.data[0].Public_Product_Name__c
            unpaidPackages.push(unpaidPackage)
          }
        }
      }
    }
    console.log('unpaidPackages', unpaidPackages)
    this.setState({openPackages: unpaidPackages})

    axios.post('https://wesnapp.co.il/api/request/get-all-open-requests-per-user', {contactId: id}, {headers: {'Content-Type': 'application/json'}}).then((response) => {
      console.log('response.data', response.data)
      this.stopLoading()
      var elements = {}
      var arrayOfOrders = []
      var orders = response.data.orders
      var counter = 0
      for (var key in orders) {
        let isPartOfPackage = false
        if (orders.hasOwnProperty(key)) {
            var sum = 0
            var rows = []
            for (var i = 0; i < orders[key].length; i++) {
              if (orders[key][i].Order_Record_Type_ID__c === '0121t0000015F5W') {
                delete orders[key]
                isPartOfPackage = true
                break
              }
              var rowData = this.createData(orders[key][i].Name, orders[key][i].Public_Product_Name__c, orders[key][i].Status__c,  orders[key][i].ShootingStreet__c + ", " + orders[key][i].ShootingCity__c, orders[key][i].Shooting_Date_Time__c, orders[key][i].Total_Cost__c)
              rows.push(rowData)
              sum += orders[key][i].Total_Cost__c
            }
            if (isPartOfPackage) {
              break
            }
            var parentOrder = response.data.parentOrders[counter]
            if (parentOrder !== null) {
              if (parentOrder in elements) {
                var temp = elements[parentOrder]
                temp.push({type: "orderUnderParent", id: response.data.ids[counter], lastDocument: response.data.lastDocuments[counter], parentOrder: response.data.parentOrders[counter], parentOrderName: response.data.parentOrdersNames[counter], parentOrderLastDocument: response.data.parentOrdersLastDocument[counter], parentOrderLastDocDate: response.data.parentOrdersLastDocDate[counter], name: key, include: false, sum: sum, requests: orders[key], rows: rows})
              } else {
                var orderData = {type: "orderUnderParent", id: response.data.ids[counter], lastDocument: response.data.lastDocuments[counter], parentOrder: response.data.parentOrders[counter], parentOrderName: response.data.parentOrdersNames[counter], parentOrderLastDocument: response.data.parentOrdersLastDocument[counter], parentOrderLastDocDate: response.data.parentOrdersLastDocDate[counter], name: key, include: false, sum: sum, requests: orders[key], rows: rows}
                elements[parentOrder] = [orderData]
              }
            } else {
              arrayOfOrders.push({type: "order", id: response.data.ids[counter], lastDocument: response.data.lastDocuments[counter], parentOrder: response.data.parentOrders[counter], name: key, include: false, sum: sum, requests: orders[key], rows: rows})
            }
            counter += 1
          }
      }

      for (var k in elements) {
        if (k.requests !== undefined) {
          arrayOfOrders.push({type: "parentOrder", parentOrder: k, parentOrderName: elements[k][0].parentOrderName, parentOrderLastDocument: elements[k][0].parentOrderLastDocument, parentOrderLastDocDate: elements[k][0].parentOrderLastDocDate, orders: elements[k], include: false})
        }
      }

      console.log('arrayOfOrders', arrayOfOrders)

      this.setState({ordersToCharge: arrayOfOrders})
      
      var accountData = response.data.account
      var contactData = response.data.contact

      var name = ""

      if (accountData.Name != null) {
        name = accountData.Name
      }
      if (accountData.Company_Name_for_Invoices__c != null) {
        name = accountData.Company_Name_for_Invoices__c
      }
      if (accountData.PersonMobilePhone == null) {
        var phone = accountData['Phone']
        this.setState({companyNameForInvoice: name, account: accountData, paymentIsReady: true, phoneNumber: phone, email: accountData.PersonEmail})
      } else {
        var mobile = accountData['PersonMobilePhone']
        this.setState({companyNameForInvoice: name, account: accountData, contact: contactData, paymentIsReady: true, phoneNumber: mobile, email: accountData.PersonEmail})
      }

    })
    .catch( (error) => {
      console.log(error);
    });
  }

  // Loading functions
  startLoading = () => {
    this.setState({isLoading: true})
  }

  stopLoading = () => {
    this.setState({isLoading: false})
  }

  // Alert functions
  handleCloseAlert = () => {
    this.setState({openAlert: false})
  }

  handleOpenAlert = (text) => {
    this.setState({alertText: text, openAlert: true})
    this.stopLoading()
  }

  logout = () => {
    // Remove token from Cookies
    const cookies = new Cookies();
    cookies.remove('wesnapp-admin-token');
    window.location.reload();
  }

  handleChangeOrdersSelection = (orderToChange) => {
    var orders = this.state.ordersToCharge
    var oneThatSelected = false
    for (var i = 0; i < orders.length; i++) {
      var order = orders[i]
      if (order.name === orderToChange) {
        orders[i].include = !order.include
        if (orders[i].include) {
          oneThatSelected = true
        }
      } else {
        if (orders[i].include) {
          oneThatSelected = true
        }
      }
    }
    if (oneThatSelected) {
      this.setState({allowToSelect: 'order'})
    } else {
      this.setState({allowToSelect: 'parentorder'})
    }
    this.setState({ordersToCharge: orders}, () => {
      this.generateBillingArea()
    })
  }

  handleChangeParentOrdersSelection = (parentOrderToCharge) => {
    var orders = this.state.ordersToCharge
    for (var i = 0; i < orders.length; i++) {
      var order = orders[i]
      if (order.type === 'parentOrder') {
        if (order.parentOrder === parentOrderToCharge) {
          orders[i].include = !order.include
          if (order.include) {
            var allowInvoice = true
            if (order.parentOrderLastDocument === 'Invoice' || order.parentOrderLastDocument === 'Invoice / Receipt') {
              allowInvoice = false
            }
            this.setState({allowToSelect: 'parent', parentOrderSelected: true, allowInvoice: allowInvoice})
          } else {
            this.setState({allowToSelect: 'parentorder', parentOrderSelected: false, allowInvoice: true})
          }
        }
      }
    }
    this.setState({ordersToCharge: orders}, () => {
      this.generateBillingArea()
    })
  }

  getTotalAmountToCharge = () => {
    if (this.state.payfor === 1) {
      var orders = this.state.ordersToCharge
      var sum = 0
      for (var i = 0; i < orders.length; i++) {
        var order = orders[i]
        if (order.type === 'order') {
          if (order.include) {
            sum += order.sum
          }
        } else {
          for (var j = 0; j < order.orders.length; j++) {
            if (order.include) {
              var childOrder = order.orders[j]
              sum += childOrder.sum
            }
          }
        }
      }
      return sum
    } else {
      if (this.state.selectedPackageToPayFor) {
        for (let index = 0; index < this.state.openPackages.length; index++) {
          const openPackage = this.state.openPackages[index];
          if (openPackage.Id === this.state.selectedPackageToPayFor) {
            return openPackage.PackageTotalCost
          }
        }
      }
    }
  }

  generateBillingArea = () => {
    if (this.state.paymentIsReady && this.state.paymentOption == 1) {
      this.generateBillingForm()
    } else if (this.state.paymentIsReady && this.state.paymentOption == 0) {
      this.generateBillingFormWithPayments()
    }  
  }

  getTotalAmountForParent = (orders) => {
    var sum = 0
    orders.forEach(order => {
      sum += order.sum
    });
    return sum
  }

  resetAllSelectionAfterMovingBetweenTabs = () => {
    var orders = this.state.ordersToCharge
    for (var i = 0; i < orders.length; i++) {
      orders[i].include = false
    }
    this.setState({ordersToCharge: orders, selectedPackageToPayFor: null, paymentOption: 1}, () => {
      this.generateBillingArea()
    })
  }

  render() {
    const { classes, theme } = this.props;
      return (
      <div className={classes.root}>
        <DescriptionDialog open={this.state.openAlert}  closeAlert={this.handleCloseAlert} text={this.state.alertText}/>
        {this.state.isLoading == true && (
          <div className="loading"><CircularProgress style={{marginTop: 'calc(40vh - 40px)'}}/><div><div style={{fontFamily: 'arial', fontSize: 25, color: 'black', marginTop: 20, fontWeight: 'bold'}}>אנא המתן</div></div></div>
        )}
        <CreateDocumentDialog main={this} />
        <PaymentsDetailsDialog main={this} />
        <CancelParentOrderDialog main={this} />
        <AppBar elevation={0} position="static" style={{textAlign: 'center', backgroundColor: 'white', border: '1px solid rgba(244, 244, 244, 1.0)'}}>
          <Toolbar>
            <Typography variant="h6" color="inherit" className={classes.grow}>
              <img src={require('../../Assets/Images/wesnappLogoBlack.png')} style={{height: 40, marginTop: 16, marginLeft: 12, marginBottom: 10}}/>
            </Typography>
            <div style={{color: "white", position:"absolute", float: 'right', right: '0', marginRight: 20}}>
              <Button onClick={() => this.logout()} elevation={0} style={{ textTransform: 'none', marginTop: 0, height: 40, width: 80, borderRadius: 20, color: 'rgb(90,88,110)', border: '1px solid rgb(90,88,110)', background: "linear-gradient(to right , #ffffff, #ffffff)"}} >
                <b>Log Out</b>
              </Button>
            </div>
            <div style={{color: "white", position:"absolute", float: 'right', right: '0', marginRight: 110}}>
              <Button onClick={() => this.createDocumentWithoutPayment('proforma')} elevation={0} style={{ textTransform: 'none', marginTop: 0, height: 40, width: 160, borderRadius: 20, color: 'rgb(90,88,110)', border: '1px solid rgb(90,88,110)', background: "linear-gradient(to right , #ffffff, #ffffff)"}} >
                <b>Create ProForma</b>
              </Button>
            </div>
            <div style={{color: "white", position:"absolute", float: 'right', right: '0', marginRight: 280}}>
              <Button disabled={!this.state.allowInvoice} onClick={() => this.createDocumentWithoutPayment('invoice')} elevation={0} style={{ textTransform: 'none', marginTop: 0, height: 40, width: 160, borderRadius: 20, color: 'rgb(90,88,110)', border: '1px solid rgb(90,88,110)', background: "linear-gradient(to right , #ffffff, #ffffff)"}} >
                <b>Create Invoice</b>
              </Button>
            </div>
            <div style={{color: "white", position:"absolute", float: 'right', right: '0', marginRight: 450}}>
              <Button onClick={() => this.setState({PaymentsDetailsDialog: true, typeOfPayment: 'receipt'})} elevation={0} style={{ textTransform: 'none', marginTop: 0, height: 40, width: 160, borderRadius: 20, color: 'rgb(90,88,110)', border: '1px solid rgb(90,88,110)', background: "linear-gradient(to right , #ffffff, #ffffff)"}} >
                <b>Create Receipt</b>
              </Button>
            </div>
            <div style={{color: "white", position:"absolute", float: 'right', right: '0', marginRight: 620}}>
              <Button disabled={!this.state.allowInvoice} onClick={() => this.setState({PaymentsDetailsDialog: true, typeOfPayment: 'invoiceReceipt'})} elevation={0} style={{ textTransform: 'none', marginTop: 0, height: 40, width: 200, borderRadius: 20, color: 'rgb(90,88,110)', border: '1px solid rgb(90,88,110)', background: "linear-gradient(to right , #ffffff, #ffffff)"}} >
                <b>Create Invoice Receipt</b>
              </Button>
            </div>
          </Toolbar>
        </AppBar>
        <main className={classes.content}>
          <Grid container spacing={2} justify={'center'}>
                <Grid item xs={12} sm={7} md={7} xl={7}>
                  <div style={{fontSize: 24, fontWeight: 'bold', textAlign: 'left'}}>
                    Charge Customer
                  </div>
                </Grid>
                <Grid item xs={12} sm={7} md={7} xl={7}>
                  <div style={{fontSize: 16, fontWeight: 'bold', textAlign: 'left'}}>
                    Client name for invoice: {this.state.companyNameForInvoice}
                  </div>
                </Grid>
                <Grid item xs={12} sm={7} md={7} xl={7}>
                  <div style={{fontSize: 16, fontWeight: 'bold', textAlign: 'left'}}>
                    Client email for invoice: {this.state.email}
                  </div>
                </Grid>
                <Grid item xs={12} sm={7} md={7} xl={7}>
                  <div style={{fontSize: 16, fontWeight: 'bold', textAlign: 'left'}}>
                    Client phone for invoice: {this.state.phoneNumber}
                  </div>
                </Grid>
                <Grid item xs={12} sm={7} md={7} xl={7}>
                  <RequestsOrPackage payfor={this.state.payfor} main={this} />
                </Grid>
                {this.state.payfor === 1 && (
                  <Grid item xs={12} sm={7} md={7} xl={7}>
                    <FormControl required component="fieldset" className={classes.formControl} style={{textAlign: 'left', marginTop: 20}}>
                      <FormLabel component="legend">Choose at least one</FormLabel>
                      <FormGroup>
                        {this.state.ordersToCharge.map((item, i) => (
                          <div>
                            {item.type === 'order' ? (
                              <div>
                                {item.parentOrder != null && (
                                  <div style={{fontSize: 16, marginTop: 16}}>Parent order: {item.parentOrder} <span onClick={()=> this.cancelParentOrderDialog(item.parentOrder)} style={{fontSize: 16, marginTop: 16, color: 'red', cursor: 'pointer', marginLeft: 5}}><u>cancel</u></span></div>
                                )}
                                {item.lastDocument === 'Invoice' && (
                                  <div style={{fontSize: 16, marginTop: 16}}>Single order: {item.id} <span onClick={()=> {this.cancelParentOrderDialog(item.id); this.setState({cancelSingleOrder: true})} } style={{fontSize: 16, marginTop: 16, color: 'red', cursor: 'pointer', marginLeft: 5}}><u>cancel</u></span></div>
                                )}
                                <FormControlLabel
                                  control={<Checkbox disabled={!this.state.allowToSelect.includes('order')} checked={item.include} onChange={() => this.handleChangeOrdersSelection(item.name)} value="gilad" />}
                                  label={"Order number " + item.name}
                                />
                                <RequestsTable rows={item.rows}/>
                                <div style={{float: 'right', fontSize: 18, marginTop: 15, fontWeight: 'bold'}}>{item.sum} ILS + VAT</div>
                              </div>
                            ) : (
                              <div>
                                <FormControlLabel
                                  control={<Checkbox disabled={!this.state.allowToSelect.includes('parent') || (!item.include && this.state.parentOrderSelected)} checked={item.include} onChange={() => this.handleChangeParentOrdersSelection(item.parentOrder)} value="gilad" />}
                                  label=""
                                />
                                <div style={{fontSize: 16, marginTop: 16, display: 'inline-block'}}>Parent order: {item.parentOrderName} <span onClick={()=> this.cancelParentOrderDialog(item.parentOrder)} style={{fontSize: 16, marginTop: 16, color: 'red', cursor: 'pointer', marginLeft: 5}}><u>cancel</u></span></div>
                                <div style={{fontSize: 16, marginTop: 16}}>Last Document: {item.parentOrderLastDocument}</div>
                                <div style={{fontSize: 16, marginTop: 16}}>Last on date: {item.parentOrderLastDocDate}</div>
                                {item.orders.map((order, j) => (
                                  <div style={{marginTop: 8}}>
                                    <div style={{fontSize: 16, marginTop: 16}}>Order number: {order.name}</div>
                                    <RequestsTable rows={order.rows} />
                                    <div style={{float: 'right', fontSize: 18, marginTop: 15, fontWeight: 'bold'}}>{order.sum} ILS + VAT</div>
                                  </div>
                                ))}
                                <Divider/>
                                <center>
                                  <div style={{float: 'center', fontSize: 18, marginTop: 50, marginBottom: 40, fontWeight: 'bold'}}>PARENT ORDER TOTAL - {this.getTotalAmountForParent(item.orders)} ILS + VAT</div>
                                </center>
                            </div>
                            )}
                          </div>
                        ))}
                        <div style={{float: 'center', width: '100%', textAlign: 'center', fontSize: 18, marginTop: 15, marginBottom: 15, fontWeight: 'bold'}}>Total {this.getTotalAmountToCharge()} ILS + VAT</div>
                      </FormGroup>
                    </FormControl>
                  </Grid>
                )}
                {this.state.payfor === 0 && (
                  <Grid item xs={12} sm={7} md={7} xl={7}>
                    <FormControl required component="fieldset" className={classes.formControl} style={{textAlign: 'left', marginTop: 20, width: '100%'}}>
                      <FormLabel component="legend">Choose one order at a time</FormLabel>
                      {/* <FormGroup></FormGroup> */}
                      {this.state.openPackages.map((item, i) => (
                        // <div style={{width: '100%'}}>
                          <Paper style={{width: '100%', padding: 24, marginTop: 24}}>
                            <FormControlLabel
                              control={<Checkbox checked={item.Id === this.state.selectedPackageToPayFor} onChange={(e) => {
                                if (this.state.selectedPackageToPayFor === item.Id) {
                                  this.setState({selectedPackageToPayFor: null}, () => {
                                    this.generateBillingArea()
                                  })
                                } else {
                                  this.setState({selectedPackageToPayFor: item.Id}, () => {
                                    this.generateBillingArea()
                                  })
                                }                              
                              }} />}
                              label={"Order number " + item.Name}
                            />
                            <div>
                              {item.PackageName}
                            </div>
                            <div>
                              {item.PackageTotalCost}
                            </div>
                          </Paper>
                        // </div>
                      ))}
                    </FormControl>
                  </Grid>
                )}
                <Grid item xs={12} sm={7} md={7} xl={7}>
                  <PaymentOptions paymentOption={this.state.paymentOption} main={this} />
                </Grid>
                <Grid item xs={12} sm={7} md={7} xl={7}>
                  {this.state.paymentURL ? (
                    <iframe style={{width: '100%', height: 550, marginTop: 0, frameBorder: 0, borderRadius: 0, border: 0}} src={this.state.paymentURL} />
                  ) : (
                    <div style={{marginTop: 15, fontWeight: 'bold', color: 'red'}}>אין יתרה שאפשר לחייב</div>
                  )}
                </Grid>
              </Grid>
        </main>
      </div>
    );
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Login);
