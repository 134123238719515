import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Cheque from './Cheque.js';
import BankTransfer from './BankTransfer.js';
import CreditCard from './CreditCard.js';
import Grid from '@material-ui/core/Grid';
import Delete from '@material-ui/icons/Delete';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function CustomizedDialogs(props) {

  const [page, setPage] = React.useState(1);

  const returnTabStyle = (index) => {
    if (props.paymentOption != index) {
      return {
        fontWeight: 'bold', height: '100%', width: '33.33%', textTransform: 'none', borderRadius: 25
      }
    } else {
      return {
        fontWeight: 'bold', height: '100%', width: '33.33%', textTransform: 'none', borderRadius: 25, color: 'white', background: "linear-gradient(to right , #544cf8, #814bfa)", marginLeft: 2
      }
    }
  }

  const handleChange = (event, value) => {
    setPage(value)
  };

  return (
    <div>
      <Dialog onClose={() => props.main.setState({PaymentsDetailsDialog: false})} aria-labelledby="customized-dialog-title" open={props.main.state.PaymentsDetailsDialog} fullWidth={"md"} maxWidth={"lg"}>
        <DialogTitle id="customized-dialog-title" onClose={() => props.main.setState({PaymentsDetailsDialog: false})}>
          Create document
        </DialogTitle>
        <DialogContent dividers>
          <div style={{minWidth: 900, minHeight: 250}}>
            <center>
              <AppBar position="static" color="default" elevation={0} style={{height: 50, width: '100%', maxWidth: 700, borderRadius: 25, marginTop: 10, marginBottom: 10, border: '1px solid rgb(225,225,225)', boxShadow: '0px 0px 35px 0px rgba(0,0,0,0.1)', backgroundColor: 'white'}}>
                <Tabs
                  value={page}
                  onChange={handleChange}
                  indicatorColor="clear"
                  textColor="primary"
                >
                  <Tab style={returnTabStyle(0)} label="צ׳ק" />
                  <Tab style={returnTabStyle(1)} label="העברה בנקאית" />
                  <Tab style={returnTabStyle(2)} label="כרטיס אשראי" />
                </Tabs>
              </AppBar>
            </center>
            <div>
              {page == 0 && (
                <div>
                  <Cheque addNewLineOfPayment={props.main.addNewLineOfPayment} handleChangeText={props.main.handleChangeText}  handleChangeSelect={props.main.handleChangeSelect}  handlePaymentDateChange={props.main.handlePaymentDateChange}  date_payment_display={props.main.state.date_payment_display}/>
                </div>
              )}
              {page == 1 && (
                <div>
                  <BankTransfer addNewLineOfPayment={props.main.addNewLineOfPayment} handleChangeText={props.main.handleChangeText} handleChangeSelect={props.main.handleChangeSelect}   handlePaymentDateChange={props.main.handlePaymentDateChange}  date_payment_display={props.main.state.date_payment_display}/>
                </div>
              )}
              {page == 2 && (
                <div>
                  <CreditCard addNewLineOfPayment={props.main.addNewLineOfPayment} handleChangeText={props.main.handleChangeText} handleChangeSelect={props.main.handleChangeSelect}   handlePaymentDateChange={props.main.handlePaymentDateChange}  date_payment_display={props.main.state.date_payment_display}/>
                </div>
              )}
            </div>
            <div>
              {props.main.state.payments.length > 0 && (
                <Grid item xs={12} sm={12} md={12}>
                  <Grid container spacing={8} alignItems="center" justifyContent="center">
                    <Grid item xs={12} sm={12} md={12}>
                      <div style={{width: '100%', height: 1, backgroundColor: 'lightGray'}}></div>
                    </Grid>
                    {props.main.state.payments.map((item, i) => {
                      return (
                        <div style={{width: '100%', height: 60, padding: 10, fontFamily: 'arial'}}>
                          <span style={{margin: 15, fontSize: 18, textAlign: 'left', float: 'left'}} onClick={() => props.main.deleteLineOfPayment(i)}><Delete/></span>
                          <span style={{margin: 15, fontSize: 18, textAlign: 'left', float: 'left'}}>{item.currencyType == 'ILS' ? ('₪') : ('$')} {item.sum}</span>
                          <span style={{margin: 15, fontSize: 18, textAlign: 'right', float: 'right'}}>{item.date_payment}</span>
                          <span style={{margin: 15, fontSize: 18, textAlign: 'right', float: 'right', direction: 'rtl'}}>{item.dataToPresent}</span>
                        </div>
                      )
                    })}
                    <Grid item xs={12} sm={12} md={12}>
                      <div style={{width: '100%', height: 1, backgroundColor: 'lightGray'}}></div>
                      <div style={{textAlign: 'center', width: '100%', height: 60, padding: 10, fontFamily: 'arial'}}>
                        <span style={{margin: 15, fontWeight: 'bold', fontSize: 18}}>{props.main.state.payments[0].currencyType == 'ILS' ? ('₪') : ('$')} {props.main.getTotalSum().toFixed(2)}</span>
                        <span style={{margin: 15, fontSize: 18}}>סה״כ שולם</span>
                      </div>
                    </Grid>
                    {
                      console.log('!@#!@#')
                    }
                    {
                      console.log(props.main.getTotalSum().toFixed(2))
                    }
                    {
                      console.log((props.main.getTotalAmountToCharge() * 1.17).toFixed(2))
                    }
                    {
                      console.log(props.main.state.payments)
                    }
                    <div style={{width: '100%', paddingBottom: 50}}>
                      {(props.main.getTotalAmountToCharge() * 1.17).toFixed(2) == props.main.getTotalSum().toFixed(2) ? (
                        <center>
                          <Button onClick={() => props.main.createDocumentBeforePayment('payment')} elevation={0} style={{marginTop: 0, textTransform: 'none', height: 40, width: 160, borderRadius: 20, textColor: 'white', color: 'white', background: "linear-gradient(to right , #544cf8, #814bfa)"}}>
                            <b>יצירת קבלה</b>
                          </Button>
                        </center>
                      ) : (
                        <center>
                          <div style={{marginTop: 0, textTransform: 'none', height: 40, width: 160, borderRadius: 20, color: 'red'}}>
                            <b>סכום התשלומים לא שווה לסכום התקבולים</b>
                          </div>
                        </center>
                      )}
                    </div>
                  </Grid>
                </Grid>
              )}
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
