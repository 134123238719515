import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import SwipeableViews from 'react-swipeable-views';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from "@material-ui/core/Button";
import Checkbox from '@material-ui/core/Checkbox';
import Theme from '../../App.js';
import DescriptionDialog from '../Dialog/DescriptionDialog.js'
// import {DateFormatInput, TimeFormatInput} from 'material-ui-next-pickers'
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Radio } from 'antd';
import { borders } from '@material-ui/system';
import Select from '@material-ui/core/Select';

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

const formatDate = 'DD/MM/YYYY';

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: '100%'
  },
  checkbox: {
    color: 'rgb(120,195,17)',
    '&$checked': {
      color: 'rgb(120,195,17)',
    },
  },
  checked: {},
});

class BankTransfer extends React.Component {
  state = {
    value: 0,
    checked: false,
    methodTypeScreen: 0,
    bank: '',
    bankBranch: '',
    accountNumber: '',
    sum: '',
    date: '',
    date_payment: '',
    date_payment_display: new Date(),
    currencyType: 'ILS'
  };

  componentDidMount() {
    this.updateDate()
  }

updateDate = () => {
    var dateObj = this.state.date_payment_display
    var m = String(dateObj.getDate())
    var d = String(dateObj.getMonth()+1)
    var y = String(dateObj.getFullYear())
    if (m.length == 1) {
      m = "0" + m
    }
    if (d.length == 1) {
      d = "0" + d
    }
    var newDate = y + "-" + d + "-" + m
    this.setState({date_payment: newDate}, () => {
      console.log(this.state.date_payment);
    })
  }

  handleChange = (event, value) => {
    this.props.main.setState({documentType: value})
    this.setState({ value });
  };

  handleChangeText = name => event => {
    this.setState({ [name]: event.target.value });
  };


  onChangeCurrency = (evnet, value) => {
    this.setState({currency: value});
  }

  onChangeLanguage = (evnet, value) => {
    this.setState({language: value});
  }

  onChangeMethodType = (event, value) => {
    this.setState({method: value});
  }

  returnTabStyle = (index) => {
    if (this.state.value != index) {
      return {
        fontWeight: 'bold', height: '100%', width: '25%', textTransform: 'none', borderRadius: 25
      }
    } else {
      return {
        fontWeight: 'bold', height: '100%', width: '25%', textTransform: 'none', borderRadius: 25, color: 'white', background: "linear-gradient(to right , #544cf8, #814bfa)", marginLeft: 2
      }
    }
  }

    handleChange = (event, value) => {
      this.props.main.setState({documentType: value})
      this.setState({ value });
    };


    handleDateChange = date => {
      this.setState({ date: date });
    };

    getDataToPresent = () => {
      var bank = this.state.bank;
      var bankBranch = this.state.bankBranch;
      var accountNumber = this.state.accountNumber;

      var dataToPresent = " העברה בנקאית / "

      dataToPresent = dataToPresent + this.state.bank + ' / ' + this.state.bankBranch
      return dataToPresent
    }

    saveLine = () => {
      if (this.state.bank == "" || this.state.bankBranch == "" || this.state.accountNumber == "" || this.state.date_payment == "") {
        alert('מלא את כל פרטי התשלום')
      } else {
        var data = {
          type: 'bank',
          bank: this.state.bank,
          bankBranch: this.state.bankBranch,
          accountNumber: this.state.accountNumber,
          sum: parseFloat(this.state.sum).toFixed(2),
          dataToPresent: this.getDataToPresent(),
          date_payment: this.state.date_payment,
          currencyType: this.state.currencyType
        }
        console.log(data);
        this.setState({bank: '', bankBranch: '', accountNumber: '' , date_payment_display: new Date(), sum: ''})
        this.updateDate()
        this.props.addNewLineOfPayment(data)
      }
    };

    handleChangeSelect = name => event => {
      console.log(name);
      console.log(event.target.value);
      this.setState({ [name]: event.target.value });
    };

    handlePaymentDateChange = date => {
      var dateObj = new Date(date);
      var m = String(dateObj.getDate())
      var d = String(dateObj.getMonth()+1)
      var y = String(dateObj.getFullYear())
      if (m.length == 1) {
        m = "0" + m
      }
      if (d.length == 1) {
        d = "0" + d
      }
      var newDate = y + "-" + d + "-" + m
      this.setState({date_payment: newDate}, () => {
        console.log(this.state.date_payment);
      })
      this.setState({date_payment_display: date}, () => {
        console.log(this.state.date_payment_display)
      })
    };
    handleChangeText = name => event => {
      this.setState({ [name]: String(event.target.value) });
    };

    handleChange = (event, value) => {
      this.props.main.setState({documentType: value})
      this.setState({ value });
    };

  render() {
    const { classes, theme } = this.props;
    return (
      <div className={classes.root} style={{padding: 10, border: '0px solid black', borderRadius: '25px', marginTop: 12, direction: 'rtl'}}  dir="rtl">
        <DescriptionDialog open={this.state.openAlert}  closeAlert={this.handleCloseAlert} text={this.state.alertText}/>
          <Grid container spacing={2} alignItems="center" justifyContent="center">
          <Grid item xs={1} sm={1} md={1}>
            <Button onClick={() => this.saveLine()} elevation={0} style={{marginTop: 25, textTransform: 'none', height: 40, width: '90%', borderRadius: 20, textColor: 'white', color: 'white', background: "linear-gradient(to right , #544cf8, #814bfa)"}} className={classes.button}>
              <b>שמור</b>
            </Button>
          </Grid>
          <Grid item xs={2} sm={2} md={2}>
            <div style={{direction: 'rtl', float: 'right', marginTop: 5, fontFamily: 'arial'}}>תאריך</div>
            <br />
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                InputProps={{
                 disableUnderline: true,
                }}
                style={{float: 'right', width: '100%',
                height: 40,
                padding: 10,
                color: 'rgb(90,88,110)',
                fontSize: '16px',
                textAlign: 'left',
                borderRadius: '20px',
                border: '1px solid rgb(225,225,225)',
                outline: 'none',
                margin: 5}}
                margin="normal"
                value={this.state.date_payment_display}
                onChange={this.handlePaymentDateChange}
              />

            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
              <div style={{direction: 'rtl', float: 'right', marginTop: 5, fontFamily: 'arial'}}>סכום</div>
              <br />
              <input type={"text"} className="CustomInput" onChange={this.handleChangeText("sum")} value={this.state.sum} placeholder="" style={{textAlign: 'right', fontFamily: 'arial'}}  required/>
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <div style={{direction: 'rtl', textAlign: 'right', marginTop: 5, fontFamily: 'arial'}}>מטבע</div>
            <Select
              native
              value={this.state.currencyType}
              onChange={this.handleChangeSelect('currencyType')}
              inputProps={{
                name: 'age',
                id: 'age-native-simple',
              }}
              className="CustomInput"
                  disableUnderline
            >
              <option value="" />
              <option value={"ILS"}>₪</option>
              <option value={"USD"}>$</option>
            </Select>
          </Grid>
            <Grid item xs={3} sm={3} md={3}>
              {
              // <div style={{direction: 'rtl', float: 'right', marginTop: 5, fontFamily: 'arial'}}>בנק</div>
              // <br />
              // <input type={"text"} className="CustomInput" onChange={this.handleChangeText("bank")} value={this.state.bank} placeholder="" style={{textAlign: 'right', fontFamily: 'arial'}}  required/>
            }
              <div style={{direction: 'rtl', textAlign: 'right', marginTop: 5, fontFamily: 'arial'}}>בנק</div>
              <Select
                native
                value={this.state.bank}
                onChange={this.handleChangeSelect('bank')}
                inputProps={{
                  name: 'bank',
                  id: 'bank-native-simple',
                }}
                className="CustomInput"
                    disableUnderline
              >
                <option value="" />
                <option value={"הפועלים"}>הפועלים - 12</option>
                <option value={"לאומי"}>לאומי - 10</option>
                <option value={"מזרחי טפחות"}>מזרחי טפחות - 20</option>
                <option value={"דיסקונט"}>דיסקונט - 11</option>
                <option value={"הבינלאומי"}>הבינלאומי - 31</option>
                <option value={"איגוד"}>איגוד - 13</option>
                <option value={"מרכנתיל"}>מרכנתיל - 17</option>
                <option value={"יהב"}>יהב - 04</option>
                <option value={"אוצר החייל"}>אוצר החייל - 14</option>
                <option value={"ירושלים"}>ירושלים - 54</option>
                <option value={"דקסיה ישראל"}>דקסיה ישראל - 68</option>
                <option value={"מסד"}>מסד - 46</option>
                <option value={"בנק הדואר"}>בנק הדואר - 09</option>
              </Select>
            </Grid>
            <Grid item xs={2} sm={2} md={2}>
              <div style={{direction: 'rtl', float: 'right', marginTop: 5, fontFamily: 'arial'}}>מספר סניף</div>
              <br />
              <input type={"text"} className="CustomInput" onChange={this.handleChangeText("bankBranch")} value={this.state.bankBranch} placeholder="" style={{textAlign: 'right', fontFamily: 'arial'}}  required/>
            </Grid>
            <Grid item xs={2} sm={2} md={2}>
              <div style={{direction: 'rtl', float: 'right', marginTop: 5, fontFamily: 'arial'}}>מספר חשבון</div>
              <br />
              <input type={"text"} className="CustomInput" onChange={this.handleChangeText("accountNumber")} value={this.state.accountNumber} placeholder="" style={{textAlign: 'right', fontFamily: 'arial'}}  required/>
            </Grid>
          </Grid>
      </div>
    );
  }
}


export default withStyles(styles, { withTheme: true })(BankTransfer);
