import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import SwipeableViews from 'react-swipeable-views';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from "@material-ui/core/Button";
import Checkbox from '@material-ui/core/Checkbox';
import Theme from '../../App.js';

function TabContainer({ children, dir }) {
  return (
    <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired,
};

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: '100%'
  },
  checkbox: {
    color: 'rgb(120,195,17)',
    '&$checked': {
      color: 'rgb(120,195,17)',
    },
  },
  checked: {},
});

class FullWidthTabs extends React.Component {
  state = {
    value: 2,
    checked: false,
  };

  handleChange = (event, value) => {
    this.props.main.setState({paymentOption: value})
    this.setState({ value });
  };

  returnTabStyle = (index) => {
    if (this.props.paymentOption != index) {
      return {
        fontWeight: 'bold', height: '100%', width: '50%', textTransform: 'none', borderRadius: 25
      }
    } else {
      return {
        fontWeight: 'bold', height: '100%', width: '50%', textTransform: 'none', borderRadius: 25, color: 'white', background: "linear-gradient(to right , #544cf8, #814bfa)", marginLeft: 2
      }
    }
  }

  render() {
    const { classes, theme } = this.props;
    return (
      <div className={classes.root}>
        <center>
          <AppBar position="static" color="default" elevation={0} style={{height: 50, width: '100%', maxWidth: 500, borderRadius: 25, marginTop: 10, marginBottom: 10, border: '1px solid rgb(225,225,225)', boxShadow: '0px 0px 35px 0px rgba(0,0,0,0.1)', backgroundColor: 'white'}}>
            <Tabs
              value={this.props.paymentOption}
              onChange={this.handleChange}
              indicatorColor="clear"
              textColor="primary"
            >
              <Tab style={this.returnTabStyle(0)} label="Regular" />
              <Tab style={this.returnTabStyle(1)} label="Payments" />
            </Tabs>
          </AppBar>
        </center>
      </div>
    );
  }
}

FullWidthTabs.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(FullWidthTabs);
