/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from "@material-ui/core/Grid";
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
import InputAdornment from "@material-ui/core/InputAdornment";
import Paper from '@material-ui/core/Paper';
import CardContent from '@material-ui/core/CardContent';
import Cookies from 'universal-cookie';
import Card from '@material-ui/core/Card';
import Collapse from '@material-ui/core/Collapse';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import uuid from 'react-native-uuid';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Person from '@material-ui/icons/Person';
import Lock from '@material-ui/icons/Lock';

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: '100%',
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
})

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

class LoginView extends React.Component {

    state = {
      username: '',
      password: '',
      showLoginAnimation: false,
      errorLogin: false,
      errorMsg: ''
    }

    componentDidMount() {
    }

    // onChangeUser = (e) => {
    //   this.setState(
    //     {
    //       username: e.target.value
    //     }
    //   )
    // }
    //
    // onChangePassword = (e) => {
    //   //console.log("handle password");
    //   this.setState(
    //     {
    //       password: e.target.value
    //     }
    //   )
    // }
    //
    // saveUUID = (token) => {
    //   var data = {"token": token, api_key: "54381eea-df2f-44fa-a311-a472f7c6f4c9" }
    //   axios.post('https://api.octofind.com/api/login/save-token',data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
    //     window.location.reload();
    //   })
    //   .catch( (error) => {
    //     //console.log(error);
    //   });
    // }
    //
    //
    // login = (data) => {
    //   // console.log(data);
    //   axios.post('https://api.octofind.com/api/login',data, {headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'}})
    //   .then((response) => {
    //     // console.log(response);
    //     if (response.data.res == "true") {
    //       const getUserUUID = response.data.uuid
    //       const cookies = new Cookies();
    //       const token = uuid.v1()
    //       console.log(response.data);
    //       cookies.set('octofind-source', response.data.source, { path: '/' });
    //       cookies.set('octofind-token', token, { path: '/' });
    //       cookies.set('octofind-user-uuid', getUserUUID, { path: '/' });
    //       this.saveUUID(token)
    //     } else {
    //       this.setState({showLoginAnimation: false, errorLogin: true, errorMsg: 'Username and password do not match.'})
    //     }
    //   })
    //   .catch( (error) => {
    //     // console.log(error);
    //     this.setState({showLoginAnimation: false, errorLogin: true, errorMsg: 'Something went wrong. Please try again later.'})
    //   });
    // }
    //
    // onSubmit = (e) => {
    //   e.preventDefault();
    //   const data = {
    //     username:this.state.username,
    //     password:this.state.password,
    //     api_key: "54381eea-df2f-44fa-a311-a472f7c6f4c9"
    //   }
    //   this.setState({showLoginAnimation: true})
    //   this.login(data);
    // }

    handleChangeText = name => event => {
      this.setState({ [name]: event.target.value });
    };

    onSubmit = (e) => {
      e.preventDefault();
      // const data = {
      //   username:this.state.username,
      //   password:this.state.password,
      //   api_key: "54381eea-df2f-44fa-a311-a472f7c6f4c9"
      // }
      // this.setState({showLoginAnimation: true})
      // this.login(data);
      if (this.state.username == '') {
        alert("Please fill username")
      } else if (this.state.password == '') {
        alert("Please fill password")
      } else {
        if (this.state.username == 'wesnapp' && this.state.password == 'wesnapp2019!') {
          this.saveUUID()
        } else {
          alert("Error username or password :(")
        }
      }
    }

    saveUUID = () => {
      const cookies = new Cookies();
      const token = uuid.v1()
      cookies.set('wesnapp-admin-token', token, { path: '/' });
      window.location.reload();
      // var data = {"token": token, api_key: "54381eea-df2f-44fa-a311-a472f7c6f4c9" }
      // axios.post('https://api.octofind.com/api/login/save-token',data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
      //   window.location.reload();
      // })
      // .catch( (error) => {
      //   //console.log(error);
      // });
    }

//     function display() {
//   var now = new Date();
//   var time = now.getTime();
//   var expireTime = time + 1000*36000;
//   now.setTime(expireTime);
//   var tempExp = 'Wed, 31 Oct 2012 08:50:17 GMT';
//   document.cookie = 'cookie=ok;expires='+now.toGMTString()+';path=/';
//   //console.log(document.cookie);
// }

    renderLoginForm = () => (
      <div style={{display: 'flex', justifyContent:'center', alignItems:'center'}}>
        <center>
          <Grid container style={{marginTop: '0vh', width: '90vw', maxWidth: 600}}>
            <Grid item xs={12} sm={12} md={12}>
              <form action="/" onSubmit={this.onSubmit}>
                <Grid container>
                  <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        id="username-imput"
                        label="Username"
                        type="string"
                        onChange={this.handleChangeText("username")}
                        margin="normal"
                        style={{width: '100%', color: '#7548f9'}}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Person style={{color: '#c1c1c1'}} />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <br/>
                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        id="password-input"
                        label="Password"
                        type="password"
                        autoComplete="current-password"
                        margin="normal"
                        style={{marginBottom: 30}}
                        style={{width: '100%', fontSize: 50, marginTop: 40}}
                        onChange={this.handleChangeText("password")}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Lock style={{color: '#c1c1c1'}} />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <br/>
                    <Grid item xs={12} sm={12} md={12}>
                      { this.state.errorLogin && (<div style={{float: 'left', color: 'red', marginTop: 10}}>{this.state.errorMsg}</div>)}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      { this.state.showLoginAnimation ? (
                        <CircularProgress style={{marginTop: 25}} />
                      ) : (
                        <Button variant="contained" type="submit" elevation={0} fullWidth style={{color: 'white', marginTop: 25, height: 55, borderRadius: 27.5, background: "linear-gradient(to right , #544cf8, #814bfa)", boxShadow: "0px 10px 71px -10px rgba(0,0,0,0.40)"}} >
                          Login
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </form>
              </Grid>
            </Grid>
          </center>
        </div>
      )
//f7f8fc
    render() {
      const { classes, ...rest } = this.props;
      return (
        <div style={{backgroundColor: '#ffffff', height: '100%'}}>
          <center>
            <Grid container>
              <Grid item xs={12} sm={12} md={12} style={{marginTop: '15%'}}>
                  <img src={require('../Assets/Images/wesnappLogoBlack.png')} style={{height: 60}}/>
              </Grid>
              <Grid item xs={12} sm={12} md={12} style={{marginTop: '2%'}}>
                  {this.renderLoginForm()}
              </Grid>
            </Grid>
          </center>
        </div>
      );
    }
  }

  LoginView.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
  };

  export default withStyles(styles, { withTheme: false })(LoginView);
